import './TopDescription.css';


function TopDescription({ size }) {
  const isMobile = size.width < 680;
//   const descriptionFontSize = 36+parseInt(size.width-800)/20;
const descriptionFontSize = 30+parseInt(size.width-800)/27;
// const descriptionFontSize = 30+parseInt(size.width-800)/27;
const mobidleDescriptionFontSize = 29+parseInt(size.width-300)/22;

  if (isMobile) { 
    return (
        <div className='Top-description-mobile'
            style={{
                fontSize: mobidleDescriptionFontSize+'px'
            }}>
            {/* <div className='description-container'>
                <div className='description-text-container'>
                    <span>
                    Research-Driven 
                    </span>
                </div>
                <div className='description-text-container'>
                <span>
                    fund investing in 
                </span>
                </div>
                <div className='description-text-container'>
                <span>
                    Ordinals & Bitcoin
                </span>
                </div>
                <div className='description-text-container'>
                <span>
                    Ecosystem
                </span>
                </div>
            </div> */}
            <div className='description-container'>
                {/* <div className='description-text-container'>
                    <span>
                        Investing in
                    </span>
                </div>
                <div className='description-text-container'>
                    <span>
                        Ordinals & Bitcoin
                    </span>
                </div>
                <div className='description-text-container'>
                    <span>
                        Ecosystem
                    </span>
                </div> */}
            </div>
        </div>
    );
  }

   return (
        <div className='Top-description'
        style={{  
            //backgroundImage: 'url(/top-bg.jpg)' 
            fontSize: descriptionFontSize+'px'
        }}
        >
            <div className='description-container'>
                <span>
                    {/* Research-Driven fund Investing in Ordinals & Bitcoin Ecosystem */}
                    {/* Investing in Ordinals & Bitcoin Ecosystem */}
                </span>
                {/* <div className='description-text-container'>
                    <br/>
                    <span>
                    Ecosystem
                    </span>
                </div> */}
            </div>
        </div>
    );
}

export default TopDescription;
