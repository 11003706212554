import { useNavigate } from 'react-router-dom';

function CreateArticle() {
    const navigate = useNavigate();

    const onClickCreate = e => {
        const id = [...Array(30)].map(() => Math.random().toString(36)[2]).join('');
        navigate('/editor/'+id);
    }

    return (
        <div className='create-article-container'>
            <button onClick={onClickCreate}>Create new article</button>
        </div>
    )
}

export default CreateArticle;

