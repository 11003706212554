import React, { useEffect, useState } from 'react';
import MediumEditor from 'medium-editor';
import axios from 'axios';
import { updateArticle, uploadImage, getArticles, deleteArticle } from './services';
import { useParams, useNavigate } from 'react-router-dom';
import { Select, Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';

import './Editor.css';

const tags = ['Bitcoin', 'Ethereum', 'DeFi', 'Regulation', 'Metaverse', 'Cryptocurrency', 'Venture'];

// const tagOptions = tags.map(tag => ({ label: tag, value: tag }));

function Editor() {
    const [content, setContent] = useState('');
    const [title, setTitle] = useState('');
    const [author, setAuthor] = useState('');
    const [twitter, setTwitter] = useState('');
    const [tags, setTags] = useState('');
    const [tagOptions, setTagOptions] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [authorImageUrl, setAuthorImageUrl] = useState('');
    const [imageUploading, setImageUploading] = useState(false);
    const [authorTitle, setAuthorTitle] = useState('');
    const [contentImageUrl, setContentImageUrl] = useState('');
    const [contentImageUploading, setContentImageUploading] = useState(false);

    const { id } = useParams();
    const navigate = useNavigate();

    const onSubmit = () => {
        updateArticle({
            author,
            id,
            title,
            content,
            tags,
            imageUrl,
            twitter,
            authorImageUrl,
            authorTitle,
            date: moment().format('lll')
        });
        navigate('/article/' + id);
        window.scrollTo(0, 0);
    };

    const onDelete = () => {
        deleteArticle(id);
        navigate('/');
    }

    const onTagChange = (_tags) => {
        setTags(_tags);
    };
    

    const onTitleChange = (e) => {
        setTitle(e.target.value);
    }

    const onAuthorChange = (e) => {
        setAuthor(e.target.value);
    }
    
    const onTwitterChange = (e) => {
        setTwitter(e.target.value);
    }

    const onAuthorTitleChange = (e) => {
        setAuthorTitle(e.target.value);
    }

    const onImageUpload = async (e) => {
        console.log('img ', e.target.files);
        setImageUploading(true);
        if (e.target.files) {
            const _imageUrl = await uploadImage(e.target.files[0], id);
            console.log('_imageUrl ', _imageUrl)
            setImageUrl(_imageUrl);
            setImageUploading(false);
        }
    }

    const onAuthorImageUpload = async (e) => {
        setImageUploading(true);
        if (e.target.files) {
            const _imageUrl = await uploadImage(e.target.files[0], 'author-'+id);
            console.log('_imageUrl ', _imageUrl)
            setAuthorImageUrl(_imageUrl);
            setImageUploading(false);
        }
    }

    const onContentImageUpload = async (e) => {
        setContentImageUploading(true);
        const imageId = [...Array(30)].map(() => Math.random().toString(36)[2]).join('');
        if (e.target.files) {
            const _imageUrl = await uploadImage(e.target.files[0], id+'-'+imageId);
            console.log('_imageUrl ', _imageUrl)
            setContentImageUrl(_imageUrl);
            setContentImageUploading(false);
        }
    }

    const fetchArticle = async () => {
        const articles = await getArticles();
        const article = articles.find(a => a.id === id);
        if (article) {
            console.log('article ', article);
            setTitle(article.title);
            document.getElementById('editable').innerHTML = article.content;
            setContent(article.content);
            setImageUrl(article.imageUrl);
            setTags(article.tags);
            setAuthor(article.author);
            setTwitter(article.twitter);
            setAuthorImageUrl(article.authorImageUrl);
            setAuthorTitle(article.authorTitle);

            // const allTags = articles.map(_article => _article.tags)
            // console.log('allTags ', allTags);
            // const tagCounts = {};
            // if (allTags) {
            //     console.log('allTags ', allTags, articles);
            //     allTags.forEach(_tags => {
            //         if (_tags) {
            //             _tags.forEach(tag => {
            //                 tagCounts[tag] = tagCounts[tag] || { name: tag, count: 0 };
            //                 tagCounts[tag].count++;
            //             });
            //         }
            //     });
            //     const sorted = _.sortBy(tagCounts, 'count').reverse().filter((tag, i) => i<6);
            //     setTagOptions(sorted.map(_tagObject => _tagObject.name));
            // }
        }
    }

    useEffect(() => {
        const editor = new MediumEditor('.editable', {
            // toolbar: false
            toolbar: {
                /* These are the default options for the toolbar,
                   if nothing is passed this is what is used */
                allowMultiParagraphSelection: true,
                buttons: ['bold', 'italic', 'underline', 'anchor', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'quote', 'image'],
                diffLeft: 0,
                diffTop: -10,
                firstButtonClass: 'medium-editor-button-first',
                lastButtonClass: 'medium-editor-button-last',
                relativeContainer: null,
                standardizeSelectionStart: false,
                static: false,
                /* options which only apply when static is true */
                align: 'center',
                sticky: false,
                updateOnEmptySelection: false
            }
        });

        editor.subscribe('editableInput', function (event, editable) {
            console.log(editable);
            setContent(editable.innerHTML);
        });
        
        fetchArticle();

      }, []);
      

    return (
        <div className='editor-page'>
            <div>Title</div>
            <textarea className='title-input' placeholder='title' onChange={onTitleChange} value={title} />

            <div>Content</div>
            <div className="editable" id='editable'>
                <p> </p>
            </div>

            <br/>
            <div>Upload Image for use in Content</div>
            <input type='file' onChange={onContentImageUpload} />
            <br/>
            <div>{
            contentImageUploading ?
            <span>Loading...</span> :
            contentImageUrl
            }</div>

            <br/>
            <div>Cover Image</div>
            <input type='file' onChange={onImageUpload} />
            <br/>
            {
                imageUrl && <img id="myImg" src={imageUrl} />
            }
                
            <br/>
            <div>Tags</div>
            <Select
                mode="tags"
                style={{ width: '100%' }}
                onChange={onTagChange}
                tokenSeparators={[',']}
                // options={tagOptions}
                value={tags}
            />
            <br/><br/>
            <div>
                Author
            </div>
            <textarea className='author-input' placeholder='author' onChange={onAuthorChange} value={author} />

            <br/>
            <div>
                Author's Twitter
            </div>
            <textarea className='author-input' placeholder='twitter' onChange={onTwitterChange} value={twitter} />

            <br/>
            <div>Author's Photo</div>
            <input type='file' onChange={onAuthorImageUpload} />
            <br/>
            {
                authorImageUrl && <img id="authorImg" src={authorImageUrl} />
            }

            <br/><br/>
            <div>
                Author's Title
            </div>
            <textarea className='author-input' onChange={onAuthorTitleChange} value={authorTitle} />


            <button className='submit-button' onClick={onSubmit} disabled={imageUploading}>Publish</button>


            <br></br><br/>
            <button className='submit-button' onClick={onDelete} disabled={imageUploading}>Delete</button>
        </div>
    )
}

export default Editor;