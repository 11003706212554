import React, { useEffect, useState } from 'react';
import { getArticles } from './services';
import moment from 'moment';
import './Articles.css';
import { useParams, useNavigate } from 'react-router-dom';
import Loading from './Loading';
import TopNav from './TopNav';
import { useWindowSize } from "@uidotdev/usehooks";
import _ from 'lodash';

// const tags = ['Bitcoin', 'Ethereum', 'DeFi', 'Regulation', 'Metaverse', 'Cryptocurrency', 'Venture'];

function Articles() {
    const [articles, setArticles] = useState([]);
    const [allArticles, setAllArticles] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tags, setTags] = useState([]);

    const navigate = useNavigate();
    const size = useWindowSize();
    const isMobile = size.width < 1400;
    
    const fetchArticles = async () => {
        setLoading(true);
        const _articles = await getArticles();
        const sortedArticles = _articles.sort((a, b) => moment(b.date).diff(moment(a.date)));
        setArticles(sortedArticles);
        setAllArticles(sortedArticles);

        // const allTags = _.countBy(_articles, 'tags');
        const allTags = _articles.map(article => article.tags)
        const tagCounts = {};
        allTags.forEach(_tags => {
            _tags.forEach(tag => {
                // tagCounts[tag] = tagCounts[tag] ? tagCounts[tag] + 1 : 1;
                tagCounts[tag] = tagCounts[tag] || { name: tag, count: 0 };
                tagCounts[tag].count++;
            });
        });

        const sorted = _.sortBy(tagCounts, 'count').reverse().filter((tag, i) => i<6);
        setTags(sorted.map(_tagObject => _tagObject.name));

        console.log('sorted ',sorted);

        setLoading(false);
    }

    useEffect(() => {
        fetchArticles();
    }, []);

    const onArticleClick = id => e => {
        navigate('/article/'+id);
        window.scrollTo(0, 0);
    }

    const onTagClick = tag => () => {
        if (selectedTags.includes(tag)) {
            setArticles(allArticles);
            setSelectedTags([]);
        } else {
            setSelectedTags([tag]);
            const _articles = allArticles.filter(article => article.tags.includes(tag));
            setArticles(_articles);
        }
    }

    if (loading) {
        return (
            <>
            <TopNav />
            <Loading style={{ backgroundColor: 'white', height: '100vh' }} />
            </>
        );
    }

    return (
        <>
        <TopNav />
        <div className='articles-page'>
            <div className='articles-main'>
                <p className={isMobile ? 'tag' : ''}>9·Bit Insights</p>
                {
                    articles.map(article => (
                        <div className='article'>
                            <div className='article-image-container'>
                                <div className='article-image' style={{ backgroundImage: `URL('${article.imageUrl}'` }} />
                            </div>
                            <div className='article-title' onClick={onArticleClick(article.id)}>
                                <span>{article.title}</span>
                            </div>
                            <div className='article-author'>
                                <p>{article.author}</p>
                            </div>
                            <div className='article-date'>
                                <p>{moment(article.date).format('ll')}</p>
                            </div>
                        </div>
                    ))
                }
    
            </div>
            <div className='topics'>
                {
                    isMobile ?
                        <p className='tag'>
                            Featured Topics
                        </p>
                        :
                        <>
                            Featured
                            <br/>
                            Topics
                            <br/>
                        </>
                }
                <br/>
                <div className='topics-list'>
                 {tags.map(tag => (
                    <p className={selectedTags.includes(tag) ? 'selected' : ''} onClick={onTagClick(tag)}>{tag}</p>
                 ))}
                </div>
                <br/>
            </div>
        </div>
        </>
    )
}

export default Articles;

