import { Flex, Spin } from 'antd';
import './Loading.css';

function Loading({ className, style }) {

  return (
    <div className={className} style={style}>
        <Flex align="center" gap="middle" className='loading-container'>
            <Spin size="large" />
        </Flex>
    </div>
  );
}

export default Loading;
