import './Footer.css';

function Footer() {
    return (
        <div className="footer">
            © 2024 9·BIT ALL RIGHTS RESERVED.
        </div>
    )
}

export default Footer;
