import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import TopNav from './TopNav';
import App from './App';
import Articles from './Articles';
import ArticlePage from './ArticlePage';
import Editor from './Editor';
import CreateArticle from './CreateArticle';
import Footer from './Footer';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <App />
    ),
  },
  {
    path: "/articles",
    element: (
      <Articles />
    ),
  },
  {
    path: "editor/:id",
    element: <Editor />,
  },
  {
    path: "article/:id",
    element: <ArticlePage />,
  },
  {
    path: "create-new",
    element: <CreateArticle />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      {/* <TopNav /> */}
      <RouterProvider router={router} />
      <Footer />
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
