import React, { useEffect, useState } from 'react';
import './App.css';
import './fonts/SpaceGrotesk-VariableFont_wght.ttf'
import { Collapse } from 'antd';
import { XOutlined } from '@ant-design/icons';
import { getArticles } from './services';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Loading from './Loading';
import TopNav from './TopNav';
import TopDescription from './TopDescription';
import { useWindowSize } from "@uidotdev/usehooks";


const items = [
  {
    key: '1',
    label: <p className='Q'>What do we invest in?    </p>,
    children: <p className='A'>
      We invest in assets/protocols/projects across Ordinals & Bitcoin ecosystem, including but not limited to L1, L2s, & BTC Metaverse.
    </p>,
  },
  {
    key: '2',
    label: <p className='Q'>What is the fund allocation?    </p>,
    children: <p className='A'>
      70% of the fund will invest in the secondary market, and 30% in the primary market.
    </p>,
  },
  {
    key: '3',
    label: <p className='Q'>What is the history of 9·Bit?</p>,
    children: <div><p className='A'>
      We are one of the earliest fund in the globe to invest in Ordinals & Bitcoin ecosystem, started since Jun.2023. Deeply rooted in Ordinals, we value Bitcoin native way of asset creation, token distribution, and community engagement.
    </p></div>,
  },
  {
    key: '4',
    label: <p className='Q'>What are some successful investment of 9·Bit?</p>,
    children: <div><p className='A'>
      9·Bit has navigated early-stage investments in BRC-20, BRC-420, Bitmap, and Atomicals through forefront research and forward-thinking industry insights. 
    </p></div>,
  },
  {
    key: '5',
    label: <p className='Q'>What are some strengths of 9·Bit?    </p>,
    children: <div><p className='A'>
      9·Bit is supported by one of the largest Bitcoin miner community in Asia, and is providing extensive community support to our partners. 
    </p></div>,
  },
];

function App() {

  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const size = useWindowSize();
  const isMobile = size.width < 680;
  const descriptionFontSize = 60+size/5;
  const descriptionFontSizeString = descriptionFontSize+'px';

  // console.log('size ', size, descriptionFontSizeString);

  const fetchArticles = async () => {
      setLoading(true);
      const _articles = await getArticles();
      const sortedArticles = _articles.sort((a, b) => moment(b.date).diff(moment(a.date)));
      setArticles(sortedArticles.filter((a, i) => i<3));
      setLoading(false);
  }

  useEffect(() => {
      fetchArticles();
  }, []);


  const onArticleClick = id => e => {
    navigate('/article/'+id);
    window.scrollTo(0, 0);
  }

  const onViewAllClick = () => {
    navigate('/articles');
    window.scrollTo(0, 0);
  }

  return (
    <>
    <TopNav isHomePage />
    <div className="App">
      <TopDescription size={size} />
      {/* <div className='square-container'>
        <div className='square'></div>
      </div> */}



      <div className='info-area'>
          <div className='tag table-Q'>What We Invest</div>
          <div className='info-list'>
            <div className='info-container'>
              <div className='info-title'>
                <span>
                  L1 
                </span>
                <span>
                  Assets
                </span>
              </div>
              <div className='info-content'>
                <p>
                  Ordinals, BRC-20, Runes, Bitmap, BRC-420, ARC-20, etc.
                </p>
              </div>
            </div>
            <div className='info-container'>
              <div className='info-title'>
                <span>
                  Bitcoin
                </span>
                <span>
                  Scaling
                </span>
              </div>
              <div className='info-content'>
                <p>
                  L1 Scalings, Sidechains, Rollups, etc.
                </p>
              </div>
            </div>
            <div className='info-container'>
              <div className='info-title'>
                <span>
                  Web3 Use Cases
                </span>
                <span>  
                  on Bitcoin
                </span>
              </div>
              <div className='info-content'>
                <p>
                  BTC-Fi, Marketplaces, Metaverse/Gaming, Creator Tools, etc. 
                </p>
              </div>
            </div>
        </div>
      </div>

      {/* <div className='tables'>

      <div className='tag table-Q'>What We Invest</div>


        <div className='table'>
          <div className='table-left'>
            <p>
              L1 
            </p>
            <br />
            <p>
              Protocols
            </p>
          </div>
          <div className='table-right'>
            <p>
              Bitcoin L1 is the world's most secure, decentralized, and permanent way of creating on-chain assets.
            </p>
          </div>
        </div>

        <div className='table'>
          <div className='table-left'>
            <p>
              L2
            </p>
            <br />
            <p>
              Ecosystem
            </p>
          </div>
          <div className='table-right'>
            <p>
              Bitcoin L2 leverages L1 assets through DeFi, GameFi, Dapps, etc.
            </p>
          </div>
        </div>

        <div className='table'>
          <div className='table-left table-left-long'>
            <p>
            Bitmap/
            </p>
            <br />
            <p>
              BRC-420
            </p>
          </div>
          <div className='table-right'>
            <p>
              Bitmap + BRC-420 is the 1st open world metaverse in human history. 
            </p>
          </div>
        </div>
        <div className='hr-container'>
          <hr className='hr' />
        </div>
      </div> */}



{/* 
      <div className='compare-area'>
        <div className='compare-left'>
          <p className='tag'>
            9·BIT Venture
          </p>
          <div className='center-container'>
            <p>
              Long term investments within the Bitcoin Ecosystem, aims to foster growth, innovation, and value discovery.
            </p>
          </div>
        </div>

        <div className='compare-right'>
          <p className='tag'>
            9·BIT Community
          </p>
          <div className='center-container'>
            <p>
              A collaborative network of individuals committed to invest in and build on Bitcoin together, with a vibrant background spanning crypto fund, Bitcoin mining, media, art, real estate, AI, and more.
            </p>
          </div>
        </div>

      </div> */}
      {
        loading ?  <div className='articles-area' id='insights'><Loading className='articles-area' style={{ padding: 0 }} /></div> :

        <div className='articles-area' id='insights'>
          <div className='articles-area-header'>
            <div className='tag'>9·Bit Insights</div>
            <div className='view-all' onClick={onViewAllClick}>View All</div>
          </div>
          <div className='article-box-list'>
            {
              articles.map(article => (
                <div className='article-box'>
                  <div className='article-image-container'>
                      <div className='article-image' style={{ backgroundImage: `URL('${article.imageUrl}'` }} />
                  </div>
                  <div className='article-title' onClick={onArticleClick(article.id)}>
                      <span>{article.title}</span>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      }

      {/* <div className='QA' id='QA'>
        <div className='tag'>Q & A</div>
       <br/><br/>

        <Collapse items={items} />
        <br/><br/><br/>

      </div> */}

    </div>
    </>
  );
}

export default App;
