import { firebase, db } from './firebase';
import { collection, doc, getDoc, getDocs, query, where, setDoc, updateDoc, deleteDoc } from 'firebase/firestore'
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

export async function updateArticle({ id, title, content, tags, imageUrl, author, twitter, authorImageUrl, authorTitle, date }) {
    const _ref = doc(db, 'Articles', id);
    setDoc(_ref, { id, title, content, tags, imageUrl, author, twitter, authorImageUrl, authorTitle, date }, { merge: true });
}


export async function getArticles() {
    const docSnap = await getDocs(collection(db, 'Articles'));
    const results = [];
    docSnap.forEach(doc => {
        const data = doc.data();
        results.push(data);
    });
    return results;
}

export async function getArticle(id) {
    const _ref = doc(db, 'Articles', id);
    const docSnap = await getDoc(_ref);
    console.log('docSnap ', id, docSnap, docSnap.data(), docSnap.exists());
    if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        return docSnap.data();
    }
}

export async function deleteArticle(id) {
    await deleteDoc(doc(db, "Articles", id));
}


export async function uploadImage(file, id) {
    const storage = getStorage();
    const storageRef = ref(storage, 'articles-' + id);

    // 'file' comes from the Blob or File API
    return await uploadBytes(storageRef, file).then((snapshot) => {
        console.log('Uploaded a blob or file! ', storageRef.fullPath);
        return getDownloadURL(snapshot.ref).then((downloadURL) => {
            console.log('downloadURL ', downloadURL);
            return downloadURL;
        });
    });
}
