import React, { useEffect, useState } from 'react';
import { getArticles } from './services';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import moment from 'moment';
import { XOutlined } from '@ant-design/icons';
import './ArticlePage.css';
import Loading from './Loading';
import TopNav from './TopNav';
import { Tag } from 'antd';
import { Helmet } from 'react-helmet-async';

const parser = new DOMParser();

function ArticlePage() {
    const [article={}, setArticle] = useState({});
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const { id } = useParams();
    console.log('id ', id);

    const fetchArticle = async () => {
        setLoading(true);
        const _articles = await getArticles();
        setLoading(false);
        const _article = _articles.find(a => a.id === id);
        setArticle(_article);
        console.log('article ', _article);
        setTimeout(() => {
            document.getElementById('content').innerHTML = _article.content
            // .replace( new RegExp("(<p([^>]*?)>)([^\]*?)(<\/p>)","gm"),"<span>$3</span>")
            // .replace( new RegExp("(<span><br\/><\/span>)","gm"),"<br\/>");
        }, 10);
   }

    const onNavClick = () => {
        navigate('/articles');
        window.scrollTo(0, 0);
    }

    const jumpToTwitter = () => {
        window.open(article.twitter);
    }

    useEffect(() => {
        fetchArticle();
    }, []);

    if (loading) {
        return (
            <>
            <TopNav />
            <Loading style={{ backgroundColor: 'white', height: '100vh' }} />
            </>
        );
    }

    return (
        <>
        <TopNav />
        <Helmet>
            <title>{article.title}</title>
            <meta property="og:type" content="website" />
            <meta name="description" content={article.title} />
            <meta property="og:url" content={`https://www.9bit.fund/article/${id}`} />
            <meta property="og:title" content={article.title} />
            <meta property="og:description" content={article.title}/>
            <meta name="twitter:image" property="twitter:image" content={article.imageUrl}></meta>
            <meta property="og:image" itemprop="image" content={article.imageUrl} />
            {/* <meta property="og:image" itemprop="image" content='https://firebasestorage.googleapis.com/v0/b/bit-9d0dc.appspot.com/o/articles-i5rf419z85u2i6n33s1zkuiadifbqa.png?alt=media&token=dc46aa8b-7aa4-49bf-838c-10cbbb64ffb8' /> */}


            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={`https://www.9bit.fund/article/${id}`} />
            <meta property="twitter:title" content={article.title} />
            <meta property="twitter:description" content={article.title} />
            <meta property="twitter:image" content={article.imageUrl}/>

        </Helmet>
        <div className='article-page'>
            <div className='author-section'>
                <div className='nav' onClick={onNavClick}>« Insights</div>
                <div className='written-by'>WRITTEN BY</div>
                <div className='article-page-author'>
                    <div className='author-image-container'>
                        <img className='author-image' src={article.authorImageUrl} />
                    </div>
                    <div>
                        <p className='author-name'>{article.author}</p>
                        <p className='author-title'>{article.authorTitle}</p>
                    </div>
                </div>
                <div className='twitter' onClick={jumpToTwitter}><XOutlined /></div>
                <br/>
                <div className='written-by'>Topics</div>
                <br/>
                <br/>
                <div className='article-tags-container'>
                {
                    article && article.tags && article.tags.map(tag => (
                        <Tag key={tag} color="#D8D8D8" className='article-tag'>{tag}</Tag>
                     ))
                }
                </div>

            </div>
            <div className='article-main'>
                <div className='article-title'>
                    <span>{article.title}</span>
                </div>
                <div className='article-date'>
                    <p>{moment(article.date).format('ll')}</p>
                </div>
                <div className='article-content' id='content'>
                </div>

            </div>
        </div>
        </>
    )
}

export default ArticlePage;

