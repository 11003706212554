import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.apiKey,
  authDomain: "bit-9d0dc.firebaseapp.com",
  projectId: "bit-9d0dc",
  storageBucket: "bit-9d0dc.appspot.com",
  messagingSenderId: "746014240629",
  appId: "1:746014240629:web:9b3c489690d8639610ed3c",
  measurementId: "G-M412E5JJYB"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const db = getFirestore(firebase);
// const analytics = getAnalytics(firebase);

export { firebase, db };
