import { XOutlined } from '@ant-design/icons';
import './TopNav.css';

function TopNav({ isHomePage } ) {
    const jumpToTwitter = () => {
        window.open('https://twitter.com/9BitFund');
    }

    const onClickLogo = () => {
        window.open('/', "_self");
    }

  const jump = () => {
    document.getElementById("QA").scrollIntoView({behavior: 'smooth'});
  }

  const jumpToInsights = () => {
    document.getElementById("insights").scrollIntoView({behavior: 'smooth'});
  }

  return (
    <div className="top-nav">
      <img className='logo' onClick={onClickLogo} src='/logo7.png'></img>
      <div className='twitter' onClick={jumpToTwitter}><XOutlined /></div>
      {
        isHomePage ?
        <>
          {/* <div className='menu' onClick={jump}>Q&A</div>  */}
          <div className='insights' onClick={jumpToInsights}>Insights</div> 
        </>
        : ''
      }
    </div>
  );
}

export default TopNav;
